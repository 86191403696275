import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LandinPageRoute from "./routes/landingpage.route";
import HomeRoute from "./routes/home/homeroute";
import ErrorRoute from "./routes/errorroute/errorroute";
import RootRoute from "./routes/rootRoute/root-route.component";
import SignIn2 from "./components/signin2/signin2.component";
import SignUp2 from "./components/signup2/signup2.component";
import Additional from "./components/additional/additional.component";
import Additional2 from "./components/additional2/additional2.components";
import Additional3 from "./components/additional3/additional3.component";
import Additional4 from "./components/additional4/additional4.component";
import Additional5 from "./components/additional5/additional5.component";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandinPageRoute />,
      errorElement: <ErrorRoute />,
    },
    {
      path: "/home",
      element: <RootRoute />,
      errorElement: <ErrorRoute />,
      children: [
        {
          path: "",
          element: <HomeRoute />,
          errorElement: <ErrorRoute />,
        },
        {
          path: "signin",
          element: <SignIn2 />,
          errorElement: <ErrorRoute />,
        },
        {
          path: "signup",
          element: <SignUp2 />,
          errorElement: <ErrorRoute />,
        },
        {
          path: "additional",
          element: <Additional />,
          errorElement: <ErrorRoute />,
        },
        {
          path: "additional2",
          element: <Additional2 />,
          errorElement: <ErrorRoute />,
        },
        {
          path: "additional3",
          element: <Additional3 />,
          errorElement: <ErrorRoute />,
        },
        {
          path: "additional4",
          element: <Additional4 />,
          errorElement: <ErrorRoute />,
        },
        {
          path: "additional5",
          element: <Additional5 />,
          errorElement: <ErrorRoute />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
