import React from "react";
import "./landingpage.style.scss";
import Input1 from "../input1/input1.component";
import producer from "./producer.png";
import gears from "./gears2.png";
import laptop from "./laptop.png";
import josestudio from "./josestudio.jpg";
const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="landing-ctn">
        <h1>SKEELLZ</h1>
        <p>The best porfolio builder for music professionals</p>
        <Input1
          btnText="DEMO MVP"
          placeholder="your email"
          inputType={"email"}
        />
      </div>
      <div className="landing-ctn2">
        <h3>
          Skeellz is an easy way for audio professionals to credit and share
          their work and gears.{" "}
        </h3>
        <img src={producer} />
      </div>
      <div className="landing-ctn3">
        <img src={laptop} />
        <h3>
          Now you can quickly credit yourself from artists on spotify, generate
          playlists of your work, link music gears to tracks, tag friends !
        </h3>
      </div>
    </div>
  );
};

export default LandingPage;
