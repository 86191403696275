import React, { useContext, useEffect, useState } from "react";
import "./signup2.style.scss";
import robots from "./smallrobot.jpg";
import roboticon from "./robot-icon.png";
import mail from "./mail.png";
import padlock from "./padlock.png";
import user from "./user.png";
import studio1 from "./studio1.jpg";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPasswordHelper,
  signUpWithEmailAndPasswordHelper,
} from "../../firebase/firebase";
import { useDispatch } from "react-redux";
import { updateUserInfos } from "../../redux/additional-slice";
const SignUp2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // -------- tout ce qui concerne le signup form ---
  const [signupForm, setSignupForm] = useState({
    displayName: "",
    email: "",
    password: "",
    passwordConf: "",
  });
  const { displayName, email, password, passwordConf } = signupForm;
  const formInputChanged = (e) => {
    const { value, name } = e.target;
    const valueWithoutSpace = value.replace(/\s/g, "");
    setSignupForm({ ...signupForm, [name]: valueWithoutSpace.toLowerCase() });
  };
  const resetForm = () => {
    setSignupForm({
      displayName: "",
      email: "",
      password: "",
      passwordConf: "",
    });
  };
  // -------- tout ce qui concerne l'envoie et la validation du signup form ---
  const [formSent, setFormSent] = useState(false);
  useEffect(() => {
    async function asyncWarper() {
      if (!formSent) return;
      const userCrendentials = await signUpWithEmailAndPasswordHelper(
        displayName,
        email,
        password
      );
      console.log(userCrendentials);
      resetForm();
      setFormSent(false);
      if (!userCrendentials) return;
      // console.log({ ...signupForm, userId: userCrendentials.user.uid });
      dispatch(
        updateUserInfos({ ...signupForm, userId: userCrendentials.user.uid })
      );
      navigate("../additional");
    }
    asyncWarper();
  }, [formSent]);
  const formSubmited = async (e) => {
    e.preventDefault();
    if (!email || !password || !passwordConf || password !== passwordConf)
      return;
    setFormSent(true);
    try {
    } catch (err) {
      console.log(err);
    }
  };
  // --------------
  return (
    <div className="signup2">
      <div className="section2">
        <div className="inner-section2">
          <form onSubmit={formSubmited}>
            <div
              className="robot-icon"
              style={{
                backgroundImage: `url(${roboticon})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <h2>Nice to meet you!</h2>
            <p>Register a new account and take control of your carreer</p>
            <div className="input-ctn">
              <div className="input-grp">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${user})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <input
                  type="text"
                  placeholder="username"
                  name="displayName"
                  value={displayName}
                  onChange={formInputChanged}
                />
              </div>
              <div className="input-grp">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${mail})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <input
                  type="email"
                  placeholder="email"
                  name="email"
                  value={email}
                  onChange={formInputChanged}
                />
              </div>

              <div className="input-grp">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${padlock})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <input
                  type="password"
                  placeholder="password"
                  name="password"
                  value={password}
                  onChange={formInputChanged}
                />
              </div>
              <div className="input-grp">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${padlock})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <input
                  type="password"
                  placeholder="password confirmation"
                  name="passwordConf"
                  value={passwordConf}
                  onChange={formInputChanged}
                />
              </div>
            </div>
            <button className="login-btn login-btn-standard ">signup</button>
            <div className="dont-have-account">
              <p>
                Already have an account ?{" "}
                <a onClick={() => navigate("../signin")}>Sign In</a>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div
        className="section1"
        style={{ backgroundImage: `url(${studio1})`, backgroundSize: "cover" }}
      ></div>
    </div>
  );
};

export default SignUp2;
