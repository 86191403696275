import React, { useState } from "react";
import "./signin2.style.scss";

import roboticon from "./robot-icon.png";
import studio1 from "./studio1.jpg";
import mail from "./mail.png";
import padlock from "./padlock.png";
import { useNavigate, useOutletContext } from "react-router-dom";
import { signInWithEmailAndPasswordHelper } from "../../firebase/firebase";

const SignIn2 = () => {
  const navigate = useNavigate();
  const [signInForm, setSignInForm] = useState({
    email: "",
    password: "",
  });

  const { email, password } = signInForm;
  const formInputChanged = (e) => {
    const { value, name } = e.target;
    setSignInForm({ ...signInForm, [name]: value });
  };

  const formSubmited = async (e) => {
    e.preventDefault();
  };

  const resetForm = () => {
    setSignInForm({
      email: "",
      password: "",
    });
  };
  return (
    <div className={`signin2`}>
      <div
        className="section1"
        style={{
          backgroundImage: `url(${studio1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="section2">
        <div className="inner-section2">
          <form onSubmit={formSubmited}>
            <div
              className="robot-icon"
              style={{
                backgroundImage: `url(${roboticon})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <h2>Good to see you !</h2>
            <p>
              SignIn into your skeellz account and show the world your talent !
            </p>
            <div className="input-ctn">
              <div className="input-grp">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${mail})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <input
                  type="email"
                  placeholder="email"
                  name="email"
                  value={email}
                  onChange={formInputChanged}
                />
              </div>
              <div className="input-grp">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${padlock})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <input
                  type="password"
                  placeholder="password"
                  name="password"
                  value={password}
                  onChange={formInputChanged}
                />
              </div>
              <div className="check-box-recover-ctn">
                <div className="checkbox-ctn">
                  <input type="checkbox" />
                  <label>remember me</label>
                </div>
                <a>recover password</a>
              </div>
            </div>
            <button className="login-btn login-btn-standard ">log in</button>
            <div className="dont-have-account">
              <p>
                Don't have an account ?{" "}
                <a onClick={() => navigate("../signup")}>Sign Up</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn2;
