import React from "react";
import NavBar from "../../components/navbar/navbar.component";
import { Outlet } from "react-router-dom";
const RootRoute = () => {
  return (
    <div className="root-route">
      <NavBar />
      <Outlet />
    </div>
  );
};

export default RootRoute;
