import React, { useContext, useEffect, useState } from 'react';
import './additional.style.scss';
import { useNavigate } from 'react-router-dom';
// import studio1 from "./studio1.jpg";
import producer from './producer.jpg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedSkills,
  updateSkills,
} from '../../redux/additional-slice';

const Additional = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fullSkillsList = [
    'mixing engineer',
    'recording engineer',
    'mastering engineer',
    'beat maker',
    'musician',
    'artist',
    'none',
  ];

  const additionalInfos = useSelector(selectSelectedSkills);
  const [skillsList, setSkillsList] = useState(fullSkillsList);
  const [skillsSelection, setSkillsSelection] = useState({
    skill1: skillsList[0],
    skill2: skillsList[1],
    skill3: skillsList[2],
  });
  const [handSelectedSkills, setHandSelectedSkills] = useState(skillsSelection);
  const [customSkillsList, setCustomsSkillsList] = useState({
    skillsList1: skillsList,
    skillsList2: skillsList,
    skillsList3: skillsList,
  });
  const skeellsSelectionSubmited = (e) => {
    dispatch(updateSkills(skillsSelection));
    navigate('../additional2');
  };
  useEffect(() => {
    if (customSkillsList.skillsList1.includes('none')) {
      const index = customSkillsList.skillsList1.indexOf('none');
      const arrayCPY = [...fullSkillsList];
      arrayCPY.splice(index, 1);
      const newSkillsList2 = filterList(fullSkillsList, skillsSelection.skill1);
      const newSkillsList3 = filterList(newSkillsList2, skillsSelection.skill2);
      setCustomsSkillsList({
        skillsList1: arrayCPY,
        skillsList2: newSkillsList2,
        skillsList3: newSkillsList3,
      });
      setSkillsSelection({
        skill1: arrayCPY[0],
        skill2: newSkillsList2[0],
        skill3: newSkillsList3[0],
      });
    }
  }, []);

  const filterList = (listToFilter, itemToFilter) => {
    const filteredList = listToFilter.filter((item) => {
      return !item.includes(itemToFilter);
    });
    return filteredList;
  };

  const selectHasChanged = (e) => {
    const { name, value } = e.target;
    if (name === 'skill1') {
      const newList2 = filterList(fullSkillsList, value);
      const newList3 = filterList(newList2, newList2[0]);
      setCustomsSkillsList({
        skillsList1: customSkillsList.skillsList1,
        skillsList2: newList2,
        skillsList3: newList3,
      });
      setSkillsSelection({
        [name]: value,
        skill2: newList2[0],
        skill3: newList3[0],
      });
    } else if (name === 'skill2') {
      const newList3WithoutSkill2 = filterList(fullSkillsList, value);
      const newList3WithoutSkill2and1 = filterList(
        newList3WithoutSkill2,
        skillsSelection.skill1
      );
      let overAllSelection = {
        skill1: skillsSelection.skill1,
        [name]: value,
        skill3: newList3WithoutSkill2and1[0],
      };
      if (value === 'none') {
        overAllSelection = {
          skill1: skillsSelection.skill1,
          [name]: value,
          skill3: 'none',
        };
      }
      setCustomsSkillsList({
        ...customSkillsList,
        skillsList3: newList3WithoutSkill2and1,
      });
      setSkillsSelection({ ...overAllSelection });
    } else if (name === 'skill3') {
      setSkillsSelection({
        ...skillsSelection,
        [name]: value,
      });
    }
  };

  console.log(skillsSelection);
  return (
    <div className="signup2">
      <div className="section2">
        <div className="inner-section2">
          <h2>Welcome on skeellz !</h2>
          <p>
            in this section we will ask you some details to build your base
            profile and help you to get the most out of skeellz.{' '}
          </p>
          <div className="selection-ctn">
            <label>What would be your primary skill :</label>
            <select
              onChange={selectHasChanged}
              name="skill1"
              value={skillsSelection.skill1}
            >
              {customSkillsList.skillsList1.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="selection-ctn">
            <label>What would be your secondary skill :</label>
            <select
              onChange={selectHasChanged}
              name="skill2"
              value={skillsSelection.skill2}
            >
              {customSkillsList.skillsList2.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div
            className="selection-ctn"
            style={
              skillsSelection.skill2 === 'none'
                ? { display: 'none' }
                : undefined
            }
          >
            <label>What would be your third skill :</label>
            <select
              onChange={selectHasChanged}
              name="skill3"
              value={skillsSelection.skill3}
            >
              {customSkillsList.skillsList3.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          onClick={skeellsSelectionSubmited}
          className="login-btn login-btn-standard"
        >
          next
        </button>
      </div>
      <div
        className="section1"
        style={{ backgroundImage: `url(${producer})`, backgroundSize: 'cover' }}
      ></div>
    </div>
  );
};

export default Additional;
