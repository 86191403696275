import React, { useState } from "react";
import "./input1.style.scss";
import { addGuestEmailToDbHelperFn } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";
const Input1 = ({ btnText, placeholder, inputType, usage }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const formSubmited = async (e) => {
    e.preventDefault();
    if (usage === "click") return;
    if (email === "") return;
    console.log("welcome !", email);
    await addGuestEmailToDbHelperFn(email);
    setEmail("");
    navigate("home");
  };
  const emailChanged = (e) => {
    setEmail(e.target.value.toLowerCase());
  };
  console.log(usage);
  return (
    <div className="input1">
      <form onSubmit={formSubmited}>
        <input
          placeholder={placeholder}
          type={inputType}
          value={email}
          onChange={emailChanged}
        />
        <button type={usage === "click" ? "button" : "submit"}>
          {btnText}
        </button>
      </form>
    </div>
  );
};

export default Input1;
