import React, { useEffect, useState } from "react";
import "./additional4.style.scss";
import headphones from "./headphones.jpg";
import MiniCard from "../minicard1/minicard.component";
import { useDispatch } from "react-redux";
import {
  updateArtistsList,
  updateSpotifyAuthToken,
} from "../../redux/additional-slice";
import { json, useNavigate } from "react-router-dom";
const Additional4 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [artist, setArtist] = useState("");
  const [artistsList, setArtistsList] = useState([]);
  const [submitionsCount, setSubmitionsCount] = useState(0);
  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    //-------------GETTING ACCESS TOKEN FROM SPOTIFY API -- asking our server first
    fetch(`https://skeellz-backend1.herokuapp.com/authorisation`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((resp) => resp.json())
      .then((data) => {
        dispatch(updateSpotifyAuthToken(data));
        setAccessToken(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (submitionsCount === 0) return;
    fetch(`https://api.spotify.com/v1/search?q=${search}&type=artist`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        /// --- checking if the artist we enter is unique or not
        const similar = artistsList.find(
          (item) => item.id === data.artists.items[0].id
        );
        if (similar) {
          console.log("it seems you already add this artist");
          console.log(similar);
          setSearch("");
          return;
        }
        /// --- checking if the spotify server gives us an artist object ( aka good response)
        if (data.artists.items.length === 0) {
          console.log("sorry we didn't find any matching artist");
          setSearch("");
          return;
        }
        // -- otherwises its all good
        setArtistsList([...artistsList, data.artists.items[0]]);
        setSearch("");
      })
      .catch((err) => console.log(err));
  }, [submitionsCount]);
  const formSubmited = async (e) => {
    //-------------GETTING ARTIST FROM SPOTIFY API - with api token
    e.preventDefault();
    if (accessToken === "" || search === "") return;
    setSubmitionsCount(submitionsCount + 1);
  };

  const searchChanged = (e) => {
    setSearch(e.target.value);
  };
  console.log(accessToken);
  artistsList.map((artist) => console.log(artist));
  const deleteGotClicked = (artistID) => {
    //----------- DELETING ARTIST FROM LIST
    console.log("deleteGotClicked");
    console.log("this is the artist you want to delete : ");
    console.log(artistID);
    const newArtistsList = artistsList.filter(
      (artist) => artist.id !== artistID
    );
    setArtistsList(newArtistsList);
  };

  const validateArtistsList = () => {
    if (artistsList.length === 0) return;
    console.log("validate artist");
    console.log(artistsList);
    dispatch(updateArtistsList(artistsList));
    navigate("../additional5");
  };
  console.log(artistsList);

  return (
    <div className="additional4">
      <div className="section2">
        <div className="inner-section2">
          <h2>They love your work</h2>
          <p>
            Grab on Spotify some artists you collaborated with ! - of course you
            will be able to edit everything after
          </p>
          <div className="input1">
            <form onSubmit={formSubmited}>
              <input
                placeholder={"search artist"}
                type="text"
                value={search}
                onChange={searchChanged}
              />
              <button>Search</button>
            </form>
          </div>
          <div className="artist-display">
            {artistsList.map((artist) => (
              <MiniCard
                key={artist.id}
                artist={artist}
                deleteGotClicked={deleteGotClicked}
              />
            ))}
          </div>
        </div>
        <button
          className="login-btn login-btn-standard"
          onClick={validateArtistsList}
        >
          next
        </button>
      </div>
      <div
        className="section1"
        style={{
          backgroundImage: `url(${headphones})`,
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};

export default Additional4;
