import React, { useState } from "react";
import "./additional3.style.scss";
import producerbed from "./producerbed.png";
import { TextareaAutosize } from "@mui/material";
import "./additional3.style.scss";
import { useDispatch } from "react-redux";
import { updateUserBio, updateUserInfos } from "../../redux/additional-slice";
import { useNavigate } from "react-router-dom";
const Additional3 = () => {
  const [bio, setBio] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textChanged = (e) => {
    setBio(e.target.value);
  };
  const bioSub = () => {
    if (bio === "") return;
    dispatch(updateUserBio(bio));
    setBio("");
    navigate("../additional4");
  };
  console.log(bio);
  return (
    <div className="additional3">
      <div className="section2">
        <div className="inner-section2">
          <h2>Tell us your story !</h2>
          <p>
            write a short bio about yourself, your connection to music and the
            people you worked with :
          </p>
          <textarea value={bio} onChange={textChanged} />
        </div>
        <button onClick={bioSub} className="login-btn login-btn-standard">
          next
        </button>
      </div>
      <div
        className="section1"
        style={{
          backgroundImage: `url(${producerbed})`,
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};

export default Additional3;
