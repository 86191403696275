import React, { useEffect, useState } from "react";
import "./navbar.style.scss";
import { Link, useLocation } from "react-router-dom";
import recordingStudio from "./recording-studio.png";
import hamburger from "./hamburger.png";
const NavBar = () => {
  const location = useLocation();
  const [currentMenuItems, setCurrentMenuItems] = useState([
    // "home",
    "signin",
    "signup",
    "exit",
  ]);
  const [toggleMenu, setToggleMenu] = useState(false);
  useEffect(() => {
    setToggleMenu(false);
  }, [location]);
  // console.log(location);
  return (
    <div className="navbar">
      <p>Skeellz</p>
      <div className="ctn2">
        <ul className={toggleMenu ? "vertical-menu" : ""}>
          {currentMenuItems.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                to={item === "home" ? "/home" : item === "exit" ? "/.." : item}
                style={
                  location.pathname.includes(item)
                    ? {
                        backgroundColor: "rgba(148, 161, 174, 0.727)",
                        color: "white",
                      }
                    : undefined
                }
              >
                {item}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="hamburger-icon"
        onClick={() => setToggleMenu(!toggleMenu)}
      >
        <img src={hamburger} />
      </div>
    </div>
  );
};

export default NavBar;
