// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxWPTMFTAM0iijV527Ct3Q05JXKCU9bmw",
  authDomain: "skeellz2.firebaseapp.com",
  projectId: "skeellz2",
  storageBucket: "skeellz2.appspot.com",
  messagingSenderId: "595359814342",
  appId: "1:595359814342:web:cfb966e79583dd56f7bdc7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
export const addGuestEmailToDbHelperFn = async (guestEmail) => {
  try {
    const docRef = doc(db, "guests", guestEmail);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      //console.log("this guest already visited");
      const guestData = docSnapshot.data();
      //console.log(guestData.visitedOn);
      updateDoc(docRef, { visitedOn: [...guestData.visitedOn, new Date()] });
      return;
    }
    // on va ajouter l'uttilisateur dans la db
    setDoc(docRef, { guestEmail: guestEmail, visitedOn: [new Date()] });
  } catch (err) {
    console.log(err);
  }
};

export const signInWithEmailAndPasswordHelper = async (email, password) => {
  console.log("heyy");
};
// ------------ my signup fonction

export const signUpWithEmailAndPasswordHelper = async (
  username,
  email,
  password
) => {
  try {
    console.log(username, email, password);
    if (!username || !email || !password) return;
    // we create this user in the firebase user
    const userCredentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    // we create this user in the firebase db
    const { displayName, uid } = userCredentials.user;
    const docRef = doc(db, "users", uid);
    const docSnapshot = getDoc(docRef);
    if ((await docSnapshot).exists()) {
      console.log("sorry this user already existst");
      throw new Error("ooop");
    }
    const displayName2 = { displayName: username };
    setDoc(docRef, {
      displayName: displayName,
      email,
      uid,
      joined: new Date(),
      ...displayName2,
    });
    return userCredentials;
  } catch (error) {
    console.log(error);
  }
};

///DO NO USE DIRECTLY
////////////--------------------------------------------------- mon template perso avec signup/ signin / storage / chat / etc;
//extrait de mon dossier "router1" marketplace

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// import {
//   getFirestore,
//   collection,
//   addDoc,
//   doc,
//   setDoc,
//   runTransaction,
//   writeBatch,
//   query,
//   getDocs,
//   updateDoc,
//   getDoc,
//   where,
//   onSnapshot,
// } from "firebase/firestore";
// import {
//   getAuth,
//   createUserWithEmailAndPassword,
//   onAuthStateChanged,
//   signOut,
//   signInWithEmailAndPassword,
// } from "firebase/auth";
// import { useDispatch } from "react-redux";
// import { store } from "../redux/store";
// import { updateMessagesSlice } from "../redux/messagesSlice";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAFp2wab7_NK0_scKexN0Z5ChVBTbFLUR8",
//   authDomain: "robotsfriends1.firebaseapp.com",
//   projectId: "robotsfriends1",
//   storageBucket: "robotsfriends1.appspot.com",
//   messagingSenderId: "491863091164",
//   appId: "1:491863091164:web:5ee067afd4591686791119",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
// const auth = getAuth();

// export const createUserWithEmailAndPasswordHelper = async (
//   email,
//   password,
//   displayName
// ) => {
//   const user = createUserWithEmailAndPassword(auth, email, password)
//     .then((resp) => {
//       const { user } = resp;
//       // console.log(user);
//       addNewUserToDbHelper(user, { displayName });
//       return user;
//     })
//     .catch((err) =>
//       console.log("Ooops ! there was an error, account seems to already exist")
//     );
//   if (user) {
//     return user;
//   }
// };

// export const onAuthStateChangedListener = (callback) => {
//   onAuthStateChanged(auth, callback);
// };

// export const signOutHelper = () => {
//   console.log("signin out user // via signOutHelper");
//   signOut(auth);
// };

// export const getUserHelper = () => {
//   const user = auth.currentUser;
//   if (user) {
//     // console.log("this is the auth user : ");
//     return user;
//   } else {
//     // console.log("there is not log in user");
//     return;
//   }
// };

// export const addNewUserToDbHelper = (user, otherData) => {
//   const userDocRef = doc(db, "users", user.uid);
//   const { email, displayName } = user;
//   setDoc(userDocRef, {
//     email: email,
//     joined: new Date(),
//     displayName: displayName,
//     viewsCount: 0,
//     ...otherData,
//   });
// };

// export const signInUserWithEmailAndPasswordHelper = async (email, password) => {
//   const user = await signInWithEmailAndPassword(auth, email, password);
//   return user;
// };

// export const addRobotsListToDb = async (robotsList) => {
//   try {
//     console.log("add robots to db");
//     const batch = writeBatch(db);
//     robotsList.forEach((robot) => {
//       const { id, firstName, lastName, email, age, gender } = robot;
//       // console.log({ id, firstName, lastName, email, age, gender });
//       const docRef = doc(db, "robots", email);
//       batch.set(docRef, {
//         id,
//         firstName,
//         lastName,
//         email,
//         age,
//         gender,
//         image: `https://api.dicebear.com/5.x/micah/svg?seed=${firstName}`,
//       });
//     });
//     batch.commit();
//   } catch {
//     console.log("error writing batch");
//   }
// };

// export const goFetcRobotsFromDbHelper = async () => {
//   try {
//     const q = query(collection(db, "robots"));
//     const querySnapshot = await getDocs(q);
//     const robotsList = [];
//     querySnapshot.forEach((doc) => robotsList.push(doc.data()));
//     // console.log(robotsList);
//     return robotsList;
//   } catch {
//     console.log("couldnt proceed to the queries");
//   }
// };
// export const goFecthAllUsersFromDbHelper = async () => {
//   try {
//     const q = query(collection(db, "users"));
//     const querySnapshot = await getDocs(q);
//     const allUsersList = [];
//     querySnapshot.forEach((doc) => allUsersList.push(doc.data()));
//     //log(robotsList);
//     return allUsersList;
//   } catch {
//     console.log(
//       "couldnt proceed to the queries // fetching all users profiles"
//     );
//   }
// };

// export const writeMessageToRobotHelper = async (message, robot) => {
//   try {
//     const robotRef = doc(db, "robots", robot.email);
//     await updateDoc(robotRef, { message: message });
//   } catch {
//     console.log("error trying writing a message inside robot document");
//   }
// };

// export const getRobotMessages = async (robot) => {
//   try {
//     const robotRef = await doc(db, "robots", robot.email);
//     const robotSnapShot = await getDoc(robotRef);
//     // console.log(robotSnapShot);
//     if (robotSnapShot.exists()) {
//       const robot = robotSnapShot.data();
//       return robot.message;
//     }
//   } catch {
//     console.log("oops");
//   }
// };

// export const updateUserInfosInDb = async (currentUserUid, newData) => {
//   // console.log(currentUserUid);
//   // console.log(newData);
//   try {
//     const currentSignUpUserRef = await doc(db, "users", currentUserUid);
//     // const { gender, skillsList, likedBy } = newData;
//     await updateDoc(currentSignUpUserRef, newData);
//   } catch {
//     console.log("oopss");
//   }
// };

// export const getAllUserData = async (currentUserUid) => {
//   try {
//     const userDocRef = doc(db, "users", currentUserUid);
//     const userDocRefSnapshot = getDoc(userDocRef);
//     const userData = (await userDocRefSnapshot).data();
//     console.log(userData);
//     return userData;
//   } catch {
//     console.log("fail to getAllUserData - Firebase");
//   }
// };

// export const updateLikesHelper = async (whoSentLikeUid, whoReceivedLikeUid) => {
//   const targetDocRef = doc(db, "users", whoReceivedLikeUid);
//   const docSnapShot = getDoc(targetDocRef);
//   const userTargeted = (await docSnapShot).data();
//   console.log("this is the user targeted for a like :");
//   console.log(userTargeted);
//   // on cherche si l'uttilisateur target a déjà été liké,
//   // si oui found prend la valeur de l'uid de celui qui l'a liké (le whoSentLike)
//   const found = userTargeted.likedBy.find(
//     (likerUid) => likerUid === whoSentLikeUid
//   );
//   console.log(found);
//   if (found) {
//     console.log("you already liked this user !");
//     //si on a déjà liké, alors l'action de re-liker à pour conséquence d'unliker
//     // du coup on élimine whoSentLikeUid de la liste des likers
//     const updatedLikeList = userTargeted.likedBy.filter(
//       (likerUid) => likerUid !== whoSentLikeUid
//     );
//     // on met à jour l'uttilisateur whoReceivedLiked
//     updateDoc(targetDocRef, { likedBy: updatedLikeList });
//     console.log(updatedLikeList);
//     // on sort de la fonction avec un return comme ca on n'execute pas les lignes suivantes
//     // qui ne concernent le fait que l'uttilisateur n'a jamais été liké
//     return { whoSentLikeUid, whoReceivedLikeUid };
//   }
//   await updateDoc(targetDocRef, {
//     likedBy: [...userTargeted.likedBy, whoSentLikeUid],
//   });
//   console.log("sending like !");
//   return { whoSentLikeUid, whoReceivedLikeUid };
// };

// export const updateEntireUserListInDb = async (updatedList) => {
//   const targetUsersdocRef = collection(db, "users");

//   try {
//     const batch = writeBatch(db);
//     updatedList.forEach((user) => {
//       const userDocRef = doc(db, "users", user.uid);
//       setDoc(userDocRef, user);
//     });
//     batch.commit();
//   } catch (err) {
//     console.log(
//       "sorry there was an error updating the DB  -- updateEntireUserListInDb Function// FIREBASE"
//     );
//   }
// };

// export const updateProfileViewCountsHelper = async (targetUid) => {
//   const docRef = doc(db, "users", targetUid);
//   console.log(targetUid);
//   const snapShot = getDoc(docRef);
//   const numberOfViews = (await snapShot).data().viewsCount;
//   console.log(numberOfViews);
//   updateDoc(docRef, { viewsCount: numberOfViews + 1 });
//   return numberOfViews;
// };

// export const createDiscussionInDb = async (
//   userSendingName,
//   userReceivingName,
//   message
// ) => {
//   try {
//     console.log(
//       `sending user : ${userSendingName},receiving user : ${userReceivingName},message : ${message}`
//     );
//     const discussionsCollectionRef = collection(db, "discussions");
//     console.log(discussionsCollectionRef);
//     const q = query(discussionsCollectionRef);
//     const querySnapshot = await getDocs(q);
//     let alreadyChatted = false;
//     // we are doing a scan of each documents. In case there is no discussion in the discussionCollection
//     querySnapshot.forEach((document) => {
//       // console.log(document);
//       if (
//         document.id.includes(userSendingName) &&
//         document.id.includes(userReceivingName)
//       ) {
//         console.log("the two users already chatted"); //but we don't know at this point the name of there discussion.
//         console.log(`here is the name of the document : ${document.id}`);
//         // docName = document.id;
//         //now we are going to put data in this disucssion collection and document
//         //...
//         const docRef = doc(discussionsCollectionRef, document.id);
//         const discussion = document.data();
//         const newMessages = [
//           ...discussion.messages,
//           { by: userSendingName, time: Date(), message: message },
//         ];
//         updateDoc(docRef, { messages: newMessages });
//         alreadyChatted = true;
//       }
//     });
//     if (alreadyChatted === false) {
//       console.log("this 2 persons never spoke");
//       const docName = `${userSendingName} and ${userReceivingName}`;
//       const docRef = doc(discussionsCollectionRef, docName);
//       const messageObject = {
//         by: userSendingName,
//         time: new Date(),
//         message: message,
//       };
//       setDoc(docRef, {
//         between: `${userSendingName} and ${userReceivingName}`,
//         createdBy: userSendingName,
//         messages: [messageObject],
//       });
//       return messageObject;
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const listenForDiscussionUpdates = async (
//   currentUserName,
//   interlocutorName
// ) => {
//   try {
//     console.log(currentUserName, interlocutorName);

//     // --- on décrit ici la logique nécessaire pour trouver le document
//     // --- qui correspond bien à la discussion entre les deux personnes

//     const collectionRef = collection(db, "discussions");
//     const q = query(collectionRef);
//     const querySnapshot = await getDocs(q);
//     // -- docToGet va etre le document qu'il faut aller chercher et lire
//     var docToGet = "";
//     querySnapshot.forEach((document) => {
//       // console.log(document.data());
//       if (
//         document.data().between.includes(currentUserName) &&
//         document.data().between.includes(interlocutorName)
//       ) {
//         console.log(document.id);
//         docToGet = document.id;
//       }
//     });
//     if (docToGet === "") return [];
//     console.log(docToGet);
//     const docRef = doc(collectionRef, docToGet);
//     console.log(docRef);
//     const unsub = onSnapshot(docRef, (doc) => {
//       console.log("listener fire !");
//       console.log("Current data: ", doc.data());
//       store.dispatch(updateMessagesSlice(doc.data()));
//     });
//     return unsub;
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const goFetchDiscussionInDb = async (
//   currentUserName,
//   interlocutorName
// ) => {
//   try {
//     console.log(currentUserName, interlocutorName);

//     // --- on décrit ici la logique nécessaire pour trouver le document
//     // --- qui correspond bien à la discussion entre les deux personnes

//     const collectionRef = collection(db, "discussions");
//     const q = query(collectionRef);
//     const querySnapshot = await getDocs(q);
//     // -- docToGet va etre le document qu'il faut aller chercher et lire
//     var docToGet = "";
//     querySnapshot.forEach((document) => {
//       // console.log(document.data());
//       if (
//         document.data().between.includes(currentUserName) &&
//         document.data().between.includes(interlocutorName)
//       ) {
//         console.log(document.id);
//         docToGet = document.id;
//       }
//     });
//     if (docToGet === "") return [];
//     console.log(docToGet);
//     //------ - essais avec onSnapshot

//     // const unsub = onSnapshot(doc(collectionRef, docToGet), (doc) => {
//     //   console.log("heyy");
//     //   console.log("Current data: ", doc.data());
//     //   return doc.data();
//     // });
//     const docRef = doc(db, "discussions", docToGet);
//     const documentSnapShot = await getDoc(docRef);
//     const data = documentSnapShot.data();
//     console.log(data);
//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const createShopForUser = async (userUid, data) => {
//   const docRef = doc(db, "users", userUid, "shop", `item ${data[0].id}`);
//   console.log(docRef);
//   setDoc(docRef, { itemDetails: data[0] });
// };

// export const addItemInShop = async (userUid, item) => {
//   const shopRef = collection(db, "users", userUid, "shop");
//   // we are doing a query to get the number of the Items for sale in the shop,
//   // so we can icrement the name of each new item
//   const q = query(shopRef);
//   const querySnapShot = await getDocs(q);
//   let length = 0;
//   querySnapShot.forEach((doc, index) => {
//     console.log(doc.data());
//     length = length + 1;
//   });
//   console.log(length);
//   //
//   const docRef = doc(shopRef, `item ${length}`);
//   const docSnapShot = getDoc(docRef);
//   if (!docSnapShot.exists) {
//     console.log("ok, the document doesnt exist yet at this adress");
//     await setDoc(docRef, {
//       itemDetails: {
//         ...item,
//         owner: userUid,
//         quantity: 1,
//         id: 0,
//       },
//     });
//   }
//   console.log("shop");
//   const shop = await getShopFromUserInDb(userUid);
//   console.log(shop);
//   return shop;
// };

// export const getShopFromUserInDb = async (currentUserUid) => {
//   const collectionRef = collection(db, "users", currentUserUid, "shop");
//   const q = query(collectionRef);
//   const querySnapShot = await getDocs(q);
//   let itemsInShopArray = [];
//   querySnapShot.forEach((doc) => itemsInShopArray.push(doc.data()));
//   console.log(itemsInShopArray);
//   return itemsInShopArray;
// };

// ///firebase storage :)
// const storage = getStorage(app);

// export const uploadImageToStorageHelper = async (file, currentUserUid) => {
//   const fileName = file.name;
//   console.log("uploadImageToStorageHelper run");
//   const imageStorageRef = ref(storage, `itemsForSale/image/${fileName}.png`);
//   console.log(fileName);
//   try {
//     console.log("checking for file");
//     const urlCheck = await getDownloadURL(imageStorageRef);
//     if (urlCheck) {
//       return urlCheck;
//     }
//   } catch (error) {
//     if (error.code === "storage/object-not-found") {
//       const fileUploadSnapShot = await uploadBytes(imageStorageRef, file);
//       const url = await getDownloadURL(imageStorageRef);

//       return url;
//     }
//   }
// };
