import React, { useEffect, useState } from "react";
import "./additional5.style.scss";
import girlstudio from "./girlstudio.jpg";
import {
  selectArtists,
  selectCurrentUserInfos,
  selectSelectedSkills,
  selectSpotifyAuthToken,
} from "../../redux/additional-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowCircleLeftRounded,
  ArrowCircleRightRounded,
} from "@mui/icons-material";
import {
  initialStateSignupCredits,
  selectSignUpCredits,
  selectSignUpCreditsArray,
  updateSignUpCredits,
} from "../../redux/signupcredits-slice";
import { ColorExtractor } from "react-color-extractor";

const Additional5 = () => {
  const dispatch = useDispatch();
  const artistsList = useSelector(selectArtists);
  const accessToken = useSelector(selectSpotifyAuthToken);
  const skills = useSelector(selectSelectedSkills);
  const user = useSelector(selectCurrentUserInfos);
  const signUpCreditsArray = useSelector(selectSignUpCreditsArray);

  let skillsArray = Object.values(skills);
  const [indexArtist, setIndexArtist] = useState(0);
  const [selectedArtist, setSelectedArtist] = useState(
    artistsList[indexArtist]
  );
  const [albumsArray, setAlbumsArray] = useState("");
  const [indexAlbums, setIndexAlbums] = useState(0);
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [isAlbumClickedOn, setIsAlbumClickedOn] = useState(false);
  const [format, setFormat] = useState("all album");
  const [albumTracks, setAlbumTracks] = useState([]);
  const [trackClickedOn, setTrackClickedOn] = useState("");
  const [trackToogle, setTrackToogle] = useState(false);
  const [skillsOnAlbum, setSkillsOnAlbum] = useState({
    albumName: "",
    skills: [],
  });
  const [colors, setColors] = useState([]);
  const formatHasChanged = (e) => {
    const { value } = e.target;
    setFormat(value);
    setSkillsOnAlbum({ albumName: "", skills: [] });
  };
  const aSkillWasClickedOn = (e) => {
    const skillClickedOn = e.target.innerText;
    let newSkillsOnAlbum = { ...skillsOnAlbum };
    //check si ce skill est déjà dans la liste, s'il n'est pas dans la liste on le retire
    // on update le state et on quite
    // d'ailleurs cela veut aussi dire qu'il y a déjà un nom sur la liste de skills
    const skillInList = skillsOnAlbum.skills.includes(skillClickedOn);
    if (skillInList) {
      const updatedList = skillsOnAlbum.skills.filter(
        (item) => item !== skillClickedOn
      );
      newSkillsOnAlbum = { ...newSkillsOnAlbum, skills: updatedList };
      setSkillsOnAlbum(newSkillsOnAlbum);
      return;
    }
    // on regarde si c'est la première fois qu'on rajoute un skills - cela se manifeste par le fait
    // que la liste n'a pas le nom de l'album
    if (skillsOnAlbum.albumName === "") {
      newSkillsOnAlbum = { ...newSkillsOnAlbum, albumName: selectedAlbum.name };
    }
    newSkillsOnAlbum = {
      ...newSkillsOnAlbum,
      skills: [...newSkillsOnAlbum.skills, skillClickedOn],
    };
    setSkillsOnAlbum(newSkillsOnAlbum);
    return;
  };
  useEffect(() => {
    //----------- fetching albums of current selected artist and storing them in state albumsArray
    fetch(`https://api.spotify.com/v1/artists/${selectedArtist.id}/albums`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setAlbumsArray(data.items);
        setSelectedAlbum(data.items[0]);
      });
  }, [selectedArtist]);

  useEffect(() => {
    if (format !== "tracks") return;
    console.log("will fetch tracks from album");
    fetch(`https://api.spotify.com/v1/albums/${selectedAlbum.id}/tracks`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => setAlbumTracks(data.items));
  }, [format]);

  const [customRGBA, setCustomRGBA] = useState("");
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  const getColors = (colors) => {
    let hex = hexToRgb(colors[0]);
    setCustomRGBA(hex);
  };
  console.log(customRGBA);
  const arrowRightGotClicked = (e) => {
    if (indexArtist + 1 === artistsList.length) return;
    setIndexArtist(indexArtist + 1);
    setSelectedArtist(artistsList[indexArtist + 1]);
    setIndexAlbums(0);
  };
  const arrowLeftGotClicked = (e) => {
    if (indexArtist === 0) return;
    setIndexArtist(indexArtist - 1);
    setSelectedArtist(artistsList[indexArtist - 1]);
    setIndexAlbums(0);
  };
  const arrowLeftGotClickedOnAlbum = () => {
    if (indexAlbums === 0) return;
    setIndexAlbums(indexAlbums - 1);
    setSelectedAlbum(albumsArray[indexAlbums - 1]);
  };
  const arrowRightGotClickedOnAlbum = () => {
    if (indexAlbums + 1 === albumsArray.length) return;
    setIndexAlbums(indexAlbums + 1);
    setSelectedAlbum(albumsArray[indexAlbums + 1]);
  };
  const clickedOnAlbum = () => {
    // la fonction ci-dessous permet de toogle le state d'état : est-ce qu'on a clické sur l'album ou non;
    // note :comme le state est asynchrone le reste de la fonction s'exécute alors que le state n'est pas encore updaté
    // donc sur la valeur opposée
    setIsAlbumClickedOn(!isAlbumClickedOn);
    if (!isAlbumClickedOn) {
      console.log("ouverture");
      // on va d'abord chercher si un credit existe déjà dans notre store
      // s'il existe on va refleter les choix dans selection de skills déjà faits dans l'UI
      let creditThatalreadyExists = signUpCreditsArray.find(
        (credit) => credit.albumID === selectedAlbum.id
      );
      if (creditThatalreadyExists) {
        skillsArray = creditThatalreadyExists.credits[0].creditedSkills;
        setSkillsOnAlbum({
          albumName: creditThatalreadyExists.albumName,
          skills: skillsArray,
        });
      }
      return;
      // on sort de la fonction
    }

    // LA LOGIQUE CI-DESSOUS CONCERNE LA SORTIE ( lorsqu'on recklique sur l'image, aka validation)
    // l'uttilisateur va faire des sélections de skills ( modif de l'array : skillsOnAlbum)
    // s'il sort alors qu'aucun skills n'a été selectionné on ne fait rien s'il n'existe pas déjà
    // on ne le crée pas
    // sinon on l'enlève de la lsite
    if (skillsOnAlbum.skills.length === 0) {
      // on check si le credit existe déjà
      let creditThatalreadyExists = signUpCreditsArray.find(
        (credit) => credit.albumID === selectedAlbum.id
      );
      if (creditThatalreadyExists) {
        const newCreditList = signUpCreditsArray.filter(
          (item) => item.albumID !== selectedAlbum.id
        );
        // on fait un check pour voir si jamais c'est le dernier élément de la liste qu'on veut enlever
        // si on le fait on va avoir une erreur car on va chercher un displayname plus tard en ligne 192
        // pour savoir si c'est le premier credit, on le réinitialise donc à la valeur de base de redux
        if (newCreditList.length === 0) {
          dispatch(
            updateSignUpCredits(initialStateSignupCredits.signUpCredits)
          );
          setSkillsOnAlbum({
            albumName: "",
            skills: [],
          });
          return;
        } else {
          // onva dispatcher la liste où est exclu l'album
          dispatch(updateSignUpCredits(newCreditList));
          // on reset les skills de l'UI et on quitte
          setSkillsOnAlbum({
            albumName: "",
            skills: [],
          });
          return;
        }
      }
    }
    /// si on arrive ici c'est qu'on est passé à travers tous les "if guards" du code
    /// on cree au moment de sotir, une variable qui correspont à un "micro credit / credit atome" qui contient juste
    // quelques informations sur l'album, l'artiste, l'uttilisateur crédité et ses skills
    let newAlbumCredit = {
      artistName: selectedArtist.name,
      artistId: selectedArtist.id,
      albumName: selectedAlbum.name,
      albumID: selectedAlbum.id,
      credits: [
        {
          userMail: user.email,
          userID: user.userId,
          creditedSkills: skillsOnAlbum.skills,
        },
      ],
    };

    // si c'est le TOUT PREMIER CREDIT, on lit la valeur par défaut de redux (le initial state du slice)
    // il ne faut donc pas spread mais remplacer cette valeur vide par l'objet newAlbumCredit
    if (signUpCreditsArray[0].artistName === "") {
      if (skillsOnAlbum.skills.length === 0) {
        // si on a rien selectionné on quitte la fonction
        return;
      }

      dispatch(updateSignUpCredits([newAlbumCredit]));
      // on va reset également la selection de skills à son état initial
      setSkillsOnAlbum({
        albumName: "",
        skills: [],
      });
      return;
    }
    /// on check si le credit existe déjà, s'il existe déjà on va le remplacer par la variable newAlbumCredit qui correspond
    // au crédit à jour
    let creditAlreadyExists = signUpCreditsArray.find(
      (credit) => credit.albumID === newAlbumCredit.albumID
    );
    if (creditAlreadyExists) {
      let newList = signUpCreditsArray.filter(
        (credit) => credit.albumID !== newAlbumCredit.albumID
      );
      newList.push(newAlbumCredit);
      dispatch(updateSignUpCredits(newList));
      // on va reset les skills qui sont display avant de sortir
      setSkillsOnAlbum({
        albumName: "",
        skills: [],
      });
      return;
    }
    // si on arrive jusqu'à là cela veut dire que ce n'est ni le tout premier credit du signup, ni que le credit existe déjà
    // c'est un nouveau crédit qu'on va simplement push dans la liste :)
    const newCreditList = [...signUpCreditsArray, newAlbumCredit];
    dispatch(updateSignUpCredits(newCreditList));
    setSkillsOnAlbum({
      albumName: "",
      skills: [],
    });
    return;
  };

  const trackLineHasBeenClicked = (e) => {
    console.log(e.target.innerText);
    setTrackClickedOn(e.target.innerText);
    setTrackToogle(!trackToogle);
  };

  console.log("album clicked on : ");
  console.log(selectedAlbum);
  console.log("signupCreditsArray : ");
  console.log(signUpCreditsArray);
  console.log("Skills on Album : ");
  console.log(skillsOnAlbum);
  console.log("Tracks on album on Album : ");
  console.log(albumTracks);
  console.log("Main colors on albums : ");
  console.log(colors);
  // cette variable updatée à chaque render va nous renvoyer un objet si un crédit existe déjà sur l'album
  // plus bas cela vas permettre de reflêter la selection de skills dans l'UI
  let albumHasSkillsOnit = signUpCreditsArray.find(
    (credit) => credit.albumID === selectedAlbum.id
  );
  console.log("albumHasSkillsOnit variable:");
  console.log(albumHasSkillsOnit);
  albumHasSkillsOnit &&
    console.log(albumHasSkillsOnit.credits[0].creditedSkills);

  return (
    <div className="additional5">
      <div className="section2">
        <div className="inner-section2">
          {!isAlbumClickedOn && (
            <>
              <h2>Tell us your role</h2>
              <p>
                Select an artist you worked with and click one of his album and
                credit your position, then you will be ask to precise tracks or
                not
              </p>
            </>
          )}
          {!isAlbumClickedOn && (
            <div className="select-cnt">
              <ArrowCircleLeftRounded
                className="arrow1"
                onClick={arrowLeftGotClicked}
                style={indexArtist === 0 ? { visibility: "hidden" } : undefined}
              />

              <img
                className="artist-picture"
                src={selectedArtist.images[1].url}
              />

              <ArrowCircleRightRounded
                className="arrow1"
                onClick={arrowRightGotClicked}
                style={
                  indexArtist + 1 === artistsList.length
                    ? { visibility: "hidden" }
                    : undefined
                }
              />
            </div>
          )}
          <div className="album-select-ctn">
            <div className="select-cnt">
              <ArrowCircleLeftRounded
                className="arrow1"
                onClick={arrowLeftGotClickedOnAlbum}
                style={
                  indexAlbums === 0 || isAlbumClickedOn
                    ? { visibility: "hidden" }
                    : undefined
                }
              />
              {albumsArray !== "" && (
                <div className="album-cover-and-dots">
                  {albumHasSkillsOnit && !isAlbumClickedOn && (
                    <div className="vertical-dots">
                      {albumHasSkillsOnit &&
                        albumHasSkillsOnit.credits[0].creditedSkills.map(
                          (skill, index) => (
                            <div
                              className={`dot ${
                                index === 0
                                  ? `orange`
                                  : index === 1
                                  ? `yellow`
                                  : `blue`
                              }`}
                              key={skill}
                            ></div>
                          )
                        )}
                    </div>
                  )}
                  <ColorExtractor getColors={getColors}>
                    <img
                      className={`artist-picture ${
                        albumHasSkillsOnit && !isAlbumClickedOn
                          ? `album-has-skills`
                          : ``
                      }`}
                      src={selectedAlbum.images[0].url}
                      onClick={clickedOnAlbum}
                    />
                  </ColorExtractor>
                </div>
              )}

              <ArrowCircleRightRounded
                className="arrow1"
                onClick={arrowRightGotClickedOnAlbum}
                style={
                  indexAlbums + 1 === albumsArray.length || isAlbumClickedOn
                    ? { visibility: "hidden" }
                    : undefined
                }
              />
            </div>
            {isAlbumClickedOn ? (
              <>
                <p style={{ fontWeight: "700" }}>
                  {`${selectedArtist.name} - ${selectedAlbum.name} `}{" "}
                </p>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    marginTop: "0px",
                  }}
                >
                  (click on album to validate)
                </p>
              </>
            ) : (
              <>
                <p style={{ fontWeight: "600" }}>{`${selectedAlbum.name} `} </p>
                {albumHasSkillsOnit && (
                  <>
                    <p>
                      {`you have 
                    ${
                      albumHasSkillsOnit.credits[0].creditedSkills.length
                    } skill${
                        albumHasSkillsOnit.credits[0].creditedSkills.length ===
                        1
                          ? ""
                          : "s"
                      } on it`}
                    </p>
                  </>
                )}
              </>
            )}
          </div>
          {isAlbumClickedOn && (
            <>
              <div className="format-select">
                <span>Credit yourself on : </span>{" "}
                <select value={format} onChange={formatHasChanged}>
                  <option value={"all album"}>all album</option>
                  <option value={"tracks"}>tracks</option>
                </select>
              </div>
              {format === "all album" && (
                <div className="roles-ctn">
                  {skillsArray.map((skill) => (
                    <span
                      key={skill}
                      name={skill}
                      onClick={aSkillWasClickedOn}
                      className={`role ${
                        skillsOnAlbum.skills.includes(skill)
                          ? `green-contour`
                          : undefined
                      }`}
                      value={skill}
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              )}
            </>
          )}
          {format === "tracks" && isAlbumClickedOn && (
            <div className="track-listing-cnt">
              {albumTracks.map((item) => (
                <div
                  className={
                    trackClickedOn === item.name && trackToogle
                      ? "selected-track-menu"
                      : undefined
                  }
                  key={item.name}
                >
                  <div
                    className={`track-line ${
                      trackClickedOn === item.name ? `track-line-selected` : ""
                    }`}
                    name={item.name}
                    value={item.name}
                    key={item.name}
                    onClick={trackLineHasBeenClicked}
                  >
                    <span className="track-line-text">{item.name}</span>
                  </div>
                  {trackClickedOn === item.name && trackToogle && (
                    <div>
                      select your skills on this track :
                      <div className="roles-ctn-track">
                        {skillsArray.map((skill) => (
                          <span
                            key={skill}
                            name={skill}
                            onClick={aSkillWasClickedOn}
                            className={`role ${
                              skillsOnAlbum.skills.includes(skill)
                                ? `green-contour`
                                : undefined
                            }`}
                            value={skill}
                          >
                            {skill}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {!isAlbumClickedOn && (
          <button className="login-btn login-btn-standard">next</button>
        )}
      </div>
      <div
        className="section1"
        style={{
          backgroundImage: `url(${girlstudio})`,
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};

export default Additional5;
