import React from "react";

const ErrorRoute = () => {
  return (
    <div className="error-route">
      <h1>Ooooop page not found !</h1>
    </div>
  );
};

export default ErrorRoute;
