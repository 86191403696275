import React from "react";
import "./minicard.style.scss";
const MiniCard = ({ artist, deleteGotClicked }) => {
  console.log(artist.id);
  return (
    <div
      className="artist-img"
      style={{ backgroundImage: `url(${artist.images[0].url})` }}
    >
      <div className="name-drop">
        <h3>{artist.name}</h3>
        <div className="cross" onClick={() => deleteGotClicked(artist.id)}>
          X
        </div>
      </div>
    </div>
  );
};

export default MiniCard;
