import { create } from "@mui/material/styles/createTransitions";
import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSkills: {
    skill1: "mixing",
    skill2: "recording",
    skill3: "assistant",
  },
  userInfos: {
    displayName: "",
    email: "",
    joined: "",
    userId: "",
  },
  profilePictureUrl: "",
  profilePictureRawFile: {},
  profileBio: "",
  artistsList: [],
  spotifyAuthToken: "",
};

const additionalSlice = createSlice({
  initialState,
  name: "additionalSlice",
  reducers: {
    updateSkills: (state, action) => {
      state.selectedSkills = action.payload;
    },
    updateProfilePictureUrl: (state, action) => {
      state.profilePictureUrl = action.payload;
    },
    updateProfilePictureRawFile: (state, action) => {
      state.profilePictureRawFile = action.payload;
    },
    updateUserInfos: (state, action) => {
      state.userInfos = action.payload;
    },
    updateUserBio: (state, action) => {
      state.profileBio = action.payload;
    },
    updateArtistsList: (state, action) => {
      state.artistsList = action.payload;
    },
    updateSpotifyAuthToken: (state, action) => {
      state.spotifyAuthToken = action.payload;
    },
  },
});

export const additionalSliceReducer = additionalSlice.reducer;

export const {
  updateSkills,
  updateProfilePictureUrl,
  updateProfilePictureRawFile,
  updateUserInfos,
  updateUserBio,
  updateArtistsList,
  updateSpotifyAuthToken,
} = additionalSlice.actions;

// <--------------- SELECTORS --------->

export const selectCurrentUserInfos = createSelector(
  (state) => state.additionalInfos,
  (additionalInfos) => additionalInfos.userInfos
);

export const selectSelectedSkills = createSelector(
  (state) => state.additionalInfos,
  (additionnalInfos) => additionnalInfos.selectedSkills
);

export const selectArtists = createSelector(
  (state) => state.additionalInfos,
  (additionalInfos) => additionalInfos.artistsList
);
export const selectSpotifyAuthToken = createSelector(
  (state) => state.additionalInfos,
  (additionalInfos) => additionalInfos.spotifyAuthToken
);
