import React, { useEffect, useState } from "react";
import keyboard from "./keyboard.png";
import "./additional2.style.scss";
import avatar1 from "./avatar1.jpg";
import avatar2 from "./avatar2.jpg";
import avatar3 from "./avatar3.jpg";
import avatar4 from "./avatar4.jpg";
import avatar5 from "./avatar5.jpg";
import avatar6 from "./avatar6.jpg";
import { useDispatch } from "react-redux";
import {
  updateProfilePictureRawFile,
  updateProfilePictureUrl,
  updateSkills,
} from "../../redux/additional-slice";
import { useNavigate } from "react-router-dom";
import {
  ArrowCircleLeftRounded,
  ArrowCircleRightRounded,
} from "@mui/icons-material";

const Additional2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selection, setSelection] = useState("picture");
  const [pictureUploaded, setPictureUploaded] = useState(avatar1);
  const [listPosition, setListPosition] = useState(0);
  const [currentAvatar, setCurrentAvatar] = useState(avatar1);
  const [filePath, setFilePath] = useState("");
  const pictureHasBeenSelected = (e) => {
    console.log(e.target.value);
    console.log(e.target.files[0]);
    setFilePath(e.target.value);
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setPictureUploaded(imageUrl);
  };
  const avatarBank = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6];
  const picSubmited = (e) => {
    if (selection === "picture" && pictureUploaded === avatar1) {
      console.log("please upload a picture");
      return;
    }

    dispatch(updateProfilePictureUrl(pictureUploaded));
    navigate("../additional3");
  };
  const selectPictureClicked = (e) => setSelection("picture");
  const selectAvatarClicked = (e) => setSelection("avatar");

  useEffect(() => {
    setPictureUploaded(avatarBank[0]);
  }, []);

  const arrowRightClicked = (e) => {
    if (listPosition + 1 === avatarBank.length) return;
    setListPosition(listPosition + 1);
    setPictureUploaded(avatarBank[listPosition + 1]);
  };
  const arrowLeftClicked = (e) => {
    if (listPosition === 0) return;
    setListPosition(listPosition - 1);
    setPictureUploaded(avatarBank[listPosition - 1]);
  };

  return (
    <div className="additional2">
      <div className="section2">
        <div className="inner-section2">
          <h2>Let's continue !</h2>
          <div className="picture-select-ctn">
            <div className="select-what">
              <p
                onClick={() => {
                  selectPictureClicked();
                  setPictureUploaded(avatarBank[0]);
                  setFilePath("");
                }}
                onMouseEnter={() => {
                  selectPictureClicked();
                  setPictureUploaded(avatarBank[0]);
                  setFilePath("");
                }}
                style={
                  selection === "picture"
                    ? { textDecoration: "underline" }
                    : undefined
                }
              >
                select a profile picture
              </p>
              <p
                style={
                  selection === "avatar"
                    ? { textDecoration: "underline" }
                    : undefined
                }
                onClick={() => {
                  selectAvatarClicked();
                  setPictureUploaded(avatarBank[0]);
                  setFilePath("");
                }}
                onMouseEnter={() => {
                  selectAvatarClicked();
                  setPictureUploaded(avatarBank[0]);
                  setFilePath("");
                }}
              >
                select an avatar
              </p>
            </div>
            <div className="img-ctn">
              <ArrowCircleLeftRounded
                className="arrow1"
                style={
                  selection === "picture" ? { visibility: "hidden" } : undefined
                }
                onClick={arrowLeftClicked}
              />
              <img className="profile-picture" src={pictureUploaded} />

              <ArrowCircleRightRounded
                className="arrow1"
                style={
                  selection === "picture" ? { visibility: "hidden" } : undefined
                }
                onClick={arrowRightClicked}
              />
            </div>
            <input
              type="file"
              id="profile"
              name="profile"
              accept="image/png, image/jpeg"
              value={filePath}
              onChange={pictureHasBeenSelected}
              style={
                selection === "avatar" ? { visibility: "hidden" } : undefined
              }
            ></input>
          </div>
        </div>
        <button className="login-btn login-btn-standard" onClick={picSubmited}>
          next
        </button>
      </div>
      <div
        className="section1"
        style={{ backgroundImage: `url(${keyboard})`, backgroundSize: "cover" }}
      ></div>
    </div>
  );
};

export default Additional2;
