import { createSelector, createSlice } from "@reduxjs/toolkit";

export const initialStateSignupCredits = {
  signUpCredits: [
    {
      artistName: "",
      artistId: "",
      albumName: "",
      albumID: "",
      credits: [
        {
          userMail: "",
          userID: "",
          creditedSkills: [],
        },
      ],
    },
  ],
};

const signUpCreditsSlice = createSlice({
  initialState: initialStateSignupCredits,
  name: "signUpCreditsSlice",
  reducers: {
    updateSignUpCredits: (state, action) => {
      state.signUpCredits = action.payload;
    },
  },
});

export const signUpCreditsReducer = signUpCreditsSlice.reducer;

export const { updateSignUpCredits } = signUpCreditsSlice.actions;

export const selectSignUpCreditsArray = createSelector(
  (state) => state.signUpCreditsSlice,
  (credits) => credits.signUpCredits
);
