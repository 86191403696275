import React from "react";
import "./homeroute.style.scss";
import Chest from "../../components/chest/chest.component";
import NavBar from "../../components/navbar/navbar.component";
const HomeRoute = () => {
  return (
    <div className="homeroute">
      <div style={{ marginTop: "40px" }}>
        <Chest>
          <h2>Welcome on skeellz !</h2>
        </Chest>
      </div>
    </div>
  );
};

export default HomeRoute;
