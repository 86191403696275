import React from "react";
import LandinPage2 from "../components/landingpage2/landingpage2.component";
import LandingPage from "../components/landingpage/landingpage.component";

const LandinPageRoute = () => {
  return (
    <div>
      <LandingPage />
    </div>
  );
};

export default LandinPageRoute;
