import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { additionalSliceReducer } from "./additional-slice";
import { signUpCreditsReducer } from "./signupcredits-slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

//<----------STORE--------------> now with persistance

const rootReducer = combineReducers({
  additionalInfos: additionalSliceReducer,
  signUpCreditsSlice: signUpCreditsReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const myPersistStore = persistStore(store);
